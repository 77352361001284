/* Copyright Levelise Ltd 2025 */
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import TabContainer from '../TabContainer';
import './index.css';
import { useNavigate } from 'react-router-dom';
import ClickableListItem from '../ClickableListItem';
import FleetCustomersContext from '../../contexts/FleetCustomersContext';
import DownloadBtn from '../DownloadBtn';
import { download, loading } from '../../utils/constants';

const CustomersIdList = ({ customerIds }) => {
	const navigate = useNavigate();

	const onCustomerClicked = useCallback(
		(customerId, onNewTab = false) => {
			if (customerId) {
				if (onNewTab) {
					window.open(`/customer/${customerId}`, '_blank');
				} else {
					navigate(`/customer/${customerId}`);
				}
			}
		},
		[navigate]
	);

	return (
		<div className="customers-id-list">
			<div className="title-con">
				<span>Customer ID</span>
			</div>
			{customerIds && customerIds.length ? (
				<>
					{customerIds?.map((ele, i) => {
						return (
							<ClickableListItem
								key={i}
								className="customers-customers-id"
								functionArg={ele}
								clickHandler={onCustomerClicked}
							>
								<span style={{ paddingLeft: 2 }}>{ele}</span>
							</ClickableListItem>
						);
					})}
				</>
			) : null}
		</div>
	);
};

const getAllCounts = (arr) => {
	let count = 0;

	arr.forEach((ele) => (count += ele.count));

	return count;
};

const CustomersTariffFilter = ({
	directionsAndCount,
	selectedDirectionTab,
	setSelectedDirectionTab,
	captureMethods,
	selectedCaptureMethod,
	setSelectedCaptureMethod,
	providers,
	selectedProvider,
	setSelectedProvider,
}) => {
	return (
		<div className="customers-tariff-filter-container">
			<div className="customers-tariff-filter">
				<div className="tariff-directions-container sticky-header">
					{directionsAndCount && directionsAndCount.length ? (
						<>
							{directionsAndCount.map((ele, idx) => (
								<div
									key={idx}
									className={`directions-tab ${selectedDirectionTab === ele ? 'selected' : ''}`}
									onClick={() => {
										setSelectedDirectionTab(ele);
										setSelectedCaptureMethod('');
										setSelectedProvider('');
									}}
								>
									<span>{ele}</span>
								</div>
							))}
						</>
					) : null}
				</div>

				<ul className="capture-types-list">
					{captureMethods &&
						captureMethods.map((ele, idx) => {
							if (selectedCaptureMethod !== '' && selectedCaptureMethod !== ele.key) {
								return null;
							}
							return (
								<div className="capture-method-list-con" key={idx}>
									<li
										key={idx}
										className={`capture-method-list-item ${
											selectedCaptureMethod === ele.key && !selectedProvider ? 'selected' : ''
										}`}
										onClick={() => {
											let newKey = ele.key;
											if (newKey === selectedCaptureMethod) {
												newKey = '';
											}
											setSelectedCaptureMethod(newKey);
											setSelectedProvider('');
										}}
									>
										<div className="capture-type-title-count">
											<span className="capture-type-name">
												{selectedCaptureMethod === ele.key ? (
													<FontAwesomeIcon icon={faChevronLeft} size="sm" />
												) : (
													<FontAwesomeIcon icon={faChevronRight} size="sm" />
												)}
												&nbsp;{`${ele.key === 'flatpeak' ? 'FlatPeak' : ele.key}`}
											</span>
											<span className="customers-tariff-count">{ele.count}</span>
										</div>
									</li>
									{selectedCaptureMethod === ele.key && providers && providers.length > 0 ? (
										<ul className="providers-list">
											{providers.map((provider, idx) => (
												<li
													key={idx}
													className={`providers-list-item ${
														selectedProvider === provider.key ? 'selected' : ''
													}`}
													onClick={() => {
														setSelectedProvider(provider.key);
													}}
												>
													<span>{provider.key}</span>
													<span className="customers-tariff-count">{provider.count}</span>
												</li>
											))}
										</ul>
									) : null}
								</div>
							);
						})}

					{captureMethods && selectedCaptureMethod === '' ? (
						<li
							key="last-capture-method"
							className={`capture-type-title-count ${!selectedCaptureMethod ? 'selected' : ''}`}
							onClick={() => {
								setSelectedCaptureMethod('');
							}}
						>
							<span>All</span>
							<span className="customers-tariff-count">{getAllCounts(captureMethods)}</span>
						</li>
					) : null}
				</ul>
			</div>
		</div>
	);
};

const sortCustomerIds = (ids) => {
	if (!ids || !ids.length) {
		return [];
	}

	return ids.sort((a, b) => a - b);
};

const getKeys = (obj) => Object.keys(obj).filter((key) => key !== 'count' && key !== 'customerIds');

function CustomersTariffList({ customersTariffObject, onTariffsDownloadClick, downloadAction, downloading }) {
	const fleetCustomersContext = useContext(FleetCustomersContext);
	const [directionsAndCount, setDirectionsAndCount] = useState(['import', 'export']);

	const [captureMethods, setCaptureMethods] = useState([]);
	const [providers, setProviders] = useState([]);

	const [customerIds, setCustomerIds] = useState([]);

	const filterCustomerIds = useCallback(() => {
		const { selectedCaptureMethod, selectedDirectionTab, selectedProvider } = fleetCustomersContext;
		if (!customersTariffObject) {
			return;
		}

		let selectedDirectionObj;
		if (Object.hasOwn(customersTariffObject, selectedDirectionTab)) {
			selectedDirectionObj = customersTariffObject[selectedDirectionTab];
			setCustomerIds(sortCustomerIds(selectedDirectionObj?.customerIds));
		}

		if (!selectedDirectionObj) {
			return;
		}

		const captureMethodKeys = getKeys(selectedDirectionObj);
		const captureMethodsArr = captureMethodKeys.map((key) => ({
			key,
			count: selectedDirectionObj[key].count,
		}));

		setCaptureMethods(captureMethodsArr);

		if (!selectedCaptureMethod) {
			return;
		}

		const selectedCaptureMethodObj = selectedDirectionObj[selectedCaptureMethod];
		if (selectedCaptureMethodObj) {
			setCustomerIds(sortCustomerIds(selectedCaptureMethodObj?.customerIds));
		}

		const providerKeys = getKeys(selectedCaptureMethodObj);
		const providersArr = providerKeys.map((key) => ({
			key,
			count: selectedCaptureMethodObj[key].count,
		}));
		setProviders(providersArr);

		if (!selectedProvider) {
			return;
		}

		const selectedProviderObj = selectedCaptureMethodObj[selectedProvider];
		if (selectedProviderObj) {
			setCustomerIds(sortCustomerIds(selectedProviderObj?.customerIds));
		}
	}, [
		customersTariffObject,
		fleetCustomersContext.selectedDirectionTab,
		fleetCustomersContext.selectedCaptureMethod,
		fleetCustomersContext.selectedProvider,
	]);

	useEffect(() => {
		if (customersTariffObject) {
			const keys = Object.keys(customersTariffObject);
			setDirectionsAndCount(keys);
		}
	}, [customersTariffObject]);

	useEffect(() => {
		filterCustomerIds();
	}, [filterCustomerIds]);

	return (
		<TabContainer
			title="Tariffs"
			containerStyle={{
				borderWidth: 1,
				borderColor: 'rgba(187, 187, 187, 0.33)',
				borderStyle: 'solid',
				borderRadius: 3,
			}}
			rightCornerComponent={
				<DownloadBtn
					downloadState={downloading && downloadAction === 'customers-tariff-status' ? loading : download}
					clickDownload={onTariffsDownloadClick}
					style={{
						position: 'unset',
						marginLeft: 5,
						minWidth: 20,
					}}
				/>
			}
		>
			<div className="customers-tariffs-filter-list">
				<CustomersTariffFilter
					directionsAndCount={directionsAndCount}
					selectedDirectionTab={fleetCustomersContext.selectedDirectionTab}
					setSelectedDirectionTab={fleetCustomersContext.setSelectedDirectionTab}
					captureMethods={captureMethods}
					selectedCaptureMethod={fleetCustomersContext.selectedCaptureMethod}
					setSelectedCaptureMethod={fleetCustomersContext.setSelectedCaptureMethod}
					selectedProvider={fleetCustomersContext.selectedProvider}
					setSelectedProvider={fleetCustomersContext.setSelectedProvider}
					providers={providers}
				/>
				<CustomersIdList customerIds={customerIds} />
			</div>
		</TabContainer>
	);
}

export default CustomersTariffList;
